<template>
  <div
    :class="['nanny-list-item', { 'nanny-list-item--archived': isArchived }]"
  >
    <BaseTag
      v-if="isArchived"
      :text="'Ankieta archiwalna'"
      class="nanny-list-item__tag"
    />

    <div class="top-container nanny-list-item__top-container">
      <div class="left-container top-container__left-container">
        <BlockPhotoMain
          :src="item.details.photoMain?.src"
          :default-color-num="item.details.photoMainColorNum"
          :years-label="`${item.user_profile_id_populated.age.years} ${item.user_profile_id_populated.age.postfix}`"
          :is-list-view="true"
          class="photo left-container__photo"
        />
      </div>
      <div class="middle-container top-container__middle-container">
        <div class="title-block middle-container__title-block">
          <BaseH2 :text="item.user_profile_id_populated.nameFull" />
        </div>
        <div class="description middle-container__description">
          <BaseText :html="readyDescription" :is-small="true" />
        </div>
      </div>
      <div class="right-container top-container__right-container">
        <div class="employment-block right-container__employment-block">
          <BaseH3 :text="readyEmploymentTypes" />
          <BaseText
            v-if="!readyAupair"
            :text="readyEmploymentRegularities"
            :is-small="true"
          />
          <BaseText v-if="readyAupair" :text="readyAupair" :is-small="true" />
        </div>
        <div class="experience-block right-container__experience-block">
          <BaseText
            v-if="readyExperienceYears"
            :html="readyExperienceYears"
            :is-small="true"
            class="years experience-block__years"
          />
          <BaseText
            v-if="readyEducationTypes"
            :html="readyEducationTypes"
            :is-small="true"
            class="edu-type experience-block__edu-type"
          />
          <BaseText
            v-if="readyEducationCourses && !readyEducationTypes"
            :html="readyEducationCourses"
            :is-small="true"
            class="edu-courses experience-block__edu-courses"
          />
        </div>
      </div>
    </div>

    <div class="bottom-container nanny-list-item__bottom-container">
      <div class="fee-block bottom-container__fee-block">
        <BaseGroupChipItem
          :label="readyFeeHour"
          :style="'background-color: #BFB3F9;'"
        />
      </div>
      <BaseGroupChip
        :items="readyAdvantages"
        class="advantages-block bottom-container__advantages-block"
      />
      <div class="languages-block bottom-container__languages-block">
        <img
          v-for="language in item.details.languages"
          :key="language.label"
          :src="language.iconSrc"
          class="icon languages-block__icon"
        />
      </div>
    </div>

    <div v-if="isGeo" class="geo nanny-list-item__geo">
      <SvgIconsLocation24 class="icon geo__icon" />
      <label class="label geo__label"
        >{{ item.details.location.label }} od Ciebie</label
      >
    </div>

    <div
      v-if="isViewsAmount"
      class="views-amount nanny-list-item__views-amount"
    >
      <SvgIconsViewsAmount class="icon views-amount__icon" />
      <label class="label views-amount__label">
        {{ viewsAmountString }}
      </label>
    </div>

    <div
      v-if="isWishlistable"
      @click.stop.prevent="$emit('handleWishlistToggler', item.id)"
      class="wishlist-wrapper"
    >
      <SvgIconsWishlist36
        v-show="!isWishlisted"
        class="wishlist-heart right-container__wishlist-heart"
      />
      <SvgIconsWishlist36Filled
        v-show="isWishlisted"
        class="wishlist-heart right-container__wishlist-heart right-container__wishlist-heart--filled"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { toFirstCharUpper } from "@/helpers";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import BlockPhotoMain from "@/components/BlockPhotoMain.vue";
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import BaseGroupChipItem from "@/components/UI/Base/BaseGroupChipItem.vue";
import BaseGroupChip from "@/components/UI/Base/BaseGroupChip.vue";
import SvgIconsLocation24 from "@/components/Svg/Icons/SvgIconsLocation24.vue";
import SvgIconsWishlist36 from "@/components/Svg/Icons/SvgIconsWishlist36.vue";
import SvgIconsWishlist36Filled from "@/components/Svg/Icons/SvgIconsWishlist36Filled.vue";
import SvgIconsViewsAmount from "@/components/Svg/Icons/SvgIconsViewsAmount.vue";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    BlockPhotoMain,
    BaseH3,
    BaseGroupChipItem,
    BaseGroupChip,
    SvgIconsLocation24,
    SvgIconsWishlist36,
    SvgIconsWishlist36Filled,
    SvgIconsViewsAmount,
    BaseTag,
  },

  props: {
    item: Object,
    isWishlistable: {
      type: Boolean,
      default: true,
    },
    isWishlisted: Boolean,
    isGeo: {
      type: Boolean,
      default: true,
    },
    isViewsAmount: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["handleWishlistToggler"],

  setup(props) {
    const DESC_MAX_LENGTH = 200;
    const readyDescription = computed(() => {
      let description = props.item.details.description.replace(
        /(\r\n|\n|\r)/gm,
        " "
      );
      if (description.length > DESC_MAX_LENGTH) {
        return description.slice(0, DESC_MAX_LENGTH) + "...";
      } else return description;
    });

    const readyEmploymentTypes = computed(() => {
      let types = [];
      props.item.employment.types.forEach((type) => {
        types.push(toFirstCharUpper(type.label));
      });
      return types.join(", ");
    });

    const readyAupair = computed(() => {
      let aupair;
      if (
        props.item.employment.types.length === 1 &&
        props.item.employment.types[0].slug === "full_time"
      ) {
        aupair = toFirstCharUpper(props.item.details.aupair.label);
      } else {
        aupair = undefined;
      }
      return aupair;
    });

    const readyEmploymentRegularities = computed(() => {
      let regularities = [];
      props.item.employment.regularities.forEach((regularity, index) => {
        if (index === 0) {
          regularities.push(toFirstCharUpper(regularity.label));
        } else {
          regularities.push(regularity.label.toLowerCase());
        }
      });

      return regularities.join(", ");
    });

    const readyExperienceYears = computed(() => {
      if (props.item.experience.working.years) {
        return `<b>${props.item.experience.working.years}</b> l. doświadczenia`;
      } else return undefined;
    });

    const readyEducationTypes = computed(() => {
      let types = [];
      if (props.item.education.types.length) {
        if (props.item.education.degree.slug === "student") {
          types.push(props.item.education.types[0].labelStudent);
          return `Jestem ${types.join()}`;
        } else {
          props.item.education.types.forEach((type, index) => {
            if (index === 0) {
              types.push(toFirstCharUpper(type.label));
            } else {
              types.push(type.label.toLowerCase());
            }
          });

          return `<b>${types.join(", ")}</b> wykształcenie`;
        }
      } else {
        return undefined;
      }
    });

    const readyEducationCourses = computed(() => {
      let courses = [];
      if (props.item.education.courses.length) {
        props.item.education.courses.forEach((course) => {
          courses.push(course.label);
        });
        return `Kursy: ${courses.join(", ")}`;
      } else return undefined;
    });

    const readyFeeHour = computed(() => {
      return `od <b>${props.item.details.rate.hour}</b> zł/g.`;
    });

    const readyAdvantages = computed(() => {
      let advantages = [];
      if (props.item.advantages.length) {
        props.item.advantages.forEach((advantage) => {
          advantages.push({
            slug: advantage.slug,
            label: advantage.labelShort,
          });
        });
      }
      return advantages;
    });

    const viewsAmountString = computed(() => {
      if (!props.isViewsAmount) {
        return "";
      }
      return props.item.meta.visitsAll + "";
    });

    const isArchived = computed(() => {
      return props.item.meta.status === "archived";
    });

    return {
      readyDescription,
      readyEmploymentTypes,
      readyAupair,
      readyEmploymentRegularities,
      readyExperienceYears,
      readyEducationTypes,
      readyEducationCourses,
      readyFeeHour,
      readyAdvantages,
      viewsAmountString,
      isArchived,
    };
  },
};
</script>

<style scoped>
.nanny-list-item {
  position: relative;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-flow: column;
  row-gap: 20px;

  background-color: #fff;
  border-radius: 20px;

  transition: transform 0.4s;
}
.nanny-list-item:hover {
  border: 2px solid rgba(103, 175, 241, 1);
  padding: 28px;
  cursor: pointer;

  will-change: transform;
}
.nanny-list-item--archived {
  pointer-events: none;
}
.nanny-list-item__tag {
  position: absolute;
  top: calc(var(--tag-height) / -2);
}
.nanny-list-item:hover .nanny-list-item__tag {
  top: calc((var(--tag-height) / -2) - 2px);
}
/* .nanny-list-item:focus {
  animation: list-item 0.6s;
}
@keyframes list-item {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
} */
.nanny-list-item__top-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 30px;
}
.top-container__left-container {
  min-width: fit-content;
}
.top-container__middle-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  row-gap: 4px;
}
.top-container__right-container {
  min-width: 320px;
  margin-top: 4px;

  display: flex;
  flex-flow: column;
  row-gap: 20px;

  position: relative;
}
.wishlist-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 20;
  display: flex;
  cursor: pointer;
}
.nanny-list-item:hover .wishlist-wrapper {
  top: 13px;
  right: 13px;
}
.right-container__wishlist-heart {
  opacity: 0.5;
  transition: opacity 0.2s;
}
.right-container__wishlist-heart:hover {
  opacity: 1 !important;
}
.right-container__wishlist-heart--filled {
  opacity: 1 !important;
}

.nanny-list-item__bottom-container {
  width: 100%;
  height: 40px;
  display: flex;
  flex-flow: row;
  column-gap: 10px;

  overflow: hidden;
}
.nanny-list-item--archived .nanny-list-item__top-container,
.nanny-list-item--archived .nanny-list-item__bottom-container {
  opacity: 0.4;
}
.bottom-container__fee-block {
  min-width: fit-content;
}
.nanny-list-item__bottom-container:deep(.bottom-container__advantages-block) {
  gap: 10px;
}
.bottom-container__languages-block {
  min-width: fit-content;
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  justify-content: flex-end;
}

.languages-block__icon {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.nanny-list-item__geo,
.nanny-list-item__views-amount {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 2px;
  opacity: 0.5;
  position: absolute;
  bottom: -24px;
}
.nanny-list-item__geo {
  left: 15px;
}
.nanny-list-item__views-amount {
  right: 15px;
}
.nanny-list-item:hover .nanny-list-item__geo,
.nanny-list-item:hover .nanny-list-item__views-amount {
  bottom: -26px;
}
.nanny-list-item:hover .nanny-list-item__geo {
  left: 13px;
}
.nanny-list-item:hover .nanny-list-item__views-amount {
  right: 13px;
}
.geo__label,
.views-amount__label {
  font: var(--system-font);
  font-size: 12px;
}
.geo__icon {
  width: 12px;
  height: 12px;
}
.views-amount__icon {
  margin-top: 2px;
}
</style>
